import services from "./index";
import qs from "querystring";

export default {
    get(id) {
        return services.http.get("/v1.0/recipes/" + id);
    },
    postSearch(data) {
        return services.http.post("/v1.0/recipes/search", qs.stringify(data));
    },
    getSchema(id) {
        return services.http.get("/v1.0/recipes/" + id + "/schema");
    },
    getNutrients(id) {
        return services.http.get("/v1.0/recipes/" + id + "/nutrients");
    },
    getSimilar(id) {
        return services.http.get("/v1.0/recipes/" + id + "/similar");
    },
    list(ids) {
        return services.http.post("/v1.0/recipes/list", ids);
    },

}